<template>
  <div>
    <van-form @submit="onSubmit">
      <!--区服-->
      <van-field
        readonly
        clickable
        name="server"
        :value="serverText"
        label="区服"
        placeholder="选择区服(请先选择游戏)"
        @click="serverShowPicker = true"
        :rules="[{ required: true }]"
      />
      <van-popup v-model="serverShowPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="serverList"
          @confirm="serverConfirm"
          @cancel="serverShowPicker = false"
        />
      </van-popup>
      <!--角色-->
      <van-field
          v-model="roleText"
          name="role"
          label="角色名"
          placeholder="请输入角色名(请先选择区服)"
          @input="roleChange"
          :rules="[
          { required: true, message: '请输入角色名', validator: asyncValidator },
        ]"
      >
      </van-field>
      <!--金额-->
      <van-field
        readonly
        clickable
        name="money"
        :value="moneyText"
        label="充值金额"
        placeholder="选择金额"
        @click="moneyShowPicker = true"
        :rules="[{ required: true }]"
      />
      <van-popup v-model="moneyShowPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="moneyList"
          @confirm="moneyConfirm"
          @cancel="moneyShowPicker = false"
        />
      </van-popup>
      <div class="van-cell" style="color: red;" v-show="discountTipsShow == true">
        <div class="van-cell__title">
          <span>{{ discountTips }}</span>
        </div>
      </div>
      <!--充值道具-->
      <van-field
        v-model="product_name"
        name="product_name"
        label="充值道具"
        placeholder="充值道具"
        readonly
      />
      <van-notice-bar
        v-show="discount_show == true"
        wrapable
        :scrollable="false"
        :text="discount_info"
      />
      <!--支付方式-->
      <van-cell title="支付方式"> </van-cell>
      <van-radio-group v-model="payTypeId">
        <van-cell-group>
          <van-cell clickable @click="payTypeId = '2'">
            <van-image
              class="pay_icon"
              :src="require('@/assets/credit/pay_icon/wechat.png')"
            />
            <div class="pay_desc">（支持人民币）</div>
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
          <van-cell clickable @click="payTypeId = '1'">
            <van-image
              class="pay_icon"
              :src="require('@/assets/credit/pay_icon/alipay.png')"
            />
            <div class="pay_desc">（支持人民币）</div>
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <div style="margin: 16px">
        <van-button style="width: 45%;float:left" type="primary">立即充值</van-button>
        <van-button
          style="width: 45%; float: right"
          type="info"
          @click="reset"
          native-type="button"
          >重置</van-button
        >
      </div>
    </van-form>
    <van-cell
      title="温馨提醒：充值时请确保角色在本服（非跨服地图）！"
      style="color: red"
    />
    <!-- 优惠券列表 -->
      <van-popup
        v-model="showCouponList"
        round
        position="bottom"
        style="height: 90%; padding-top: 4px"
      >
        <van-coupon-list
          :coupons="coupons"
          :chosen-coupon="chosenCoupon"
          :disabled-coupons="disabledCoupons"
          :show-exchange-bar="false"
          @change="onCouponChange"
        />
      </van-popup>
  </div>
</template>
<script>
import {
  roleInfo,
  gameList,
  serverList,
  commonCreateOrder,
  payWxRedirect,
  stripeMoneyList
} from "@/api/darkxiyouxcx";
// import { Toast, CouponCell, CouponList } from "vant";
import { Toast } from "vant";
import { Base64 } from "js-base64";
import { getDiscountInfo } from "@/api/common/discount";
const PAY_TYPE_ALIPAY = 1
const PAY_TYPE_WX = 2
const PAY_TYPE_PAYPAL = 3
const PAY_TYPE_STRIPE = 10
export default {
  data() {
    return {
      first_name:'',
      last_name:'',
      email:'',
      account: "", //账号
      accountList: [],
      accountShowPicker: false,
      user_id: 0, //用户id
      user_email: null, //用户id
      game_id: 'e32f56bad0de4933916f425ffd94f0c3', //游戏id
      is_sale: 0, //是否打折 0不打折
      discount_info: "恭喜你，本次充值额外赠送10%！", //折扣信息
      discount_show: false, //显示打折信息
      discountTips: "", //  获取折扣信息[此字段：正式使用时，初始化值为空，通过接口获取该值（包括折扣信息）]
      discountTipsShow: false, //  获取折扣信息[此字段：正式使用时，初始化值为空，通过接口获取该值（包括折扣信息）]
      money: '', //用户充值金额
      product_id: 0, //后台自增id 非product_id
      product_name: "", //物品名
      payState: false, //防止重复点击
      osId: "2",
      osText: "", //选中内容
      osList: ["苹果", "安卓"], //系统列表
      osShowPicker: false,
      gameList: [], //游戏列表
      gameShowPicker: false,
      serverText: "", //选中区服
      serverList: [], //区服列表
      serverShowPicker: false,
      roleText: "", //选中角色
      roleList: [], //角色列表
      roleId: 0,
      roleShowPicker: false,
      moneyText: "", //选中金额
      moneyList: [], //金额列表
      moneyShowPicker: false,
      payTypeId: "2",
      chosenCoupon: -1,
      showCouponList: false,
      coupons: [],
      disabledCoupons: [],
      couponId: undefined,
      checkRoleBoole: false
    };
  },
  created() {
    this.gameConfirm();
    this.products();
  },
  methods: {
    //  获取折扣信息接口
    getDiscountInfo(params) {
      getDiscountInfo(params).then((response) => {
        console.log(response)
        console.log(response.content)
        if(response.content) {
          this.discountTipsShow = true;
          this.discountTips = response.content
        } else{
          this.discountTipsShow = false;
        }
      }).catch(() => {
        //  折扣信息接口不存在
        this.discountTipsShow = false;
      });
    },
    onSubmit() {
      console.log("onSubmit");
      const that = this;
      // if (that.payState) {
      //   return false;
      // }
      that.payState = true;
      Toast.loading({
        message: "登录中，请稍等!",
        forbidClick: true,
      });
      console.log("payTypeId", parseInt(this.payTypeId));
      this.saveUserRole()
      this.orderStore()
    },
    reset: function () {
      this.changeReset("all");
    },
    gameConfirm() {
      this.gameShowPicker = false;
      this.changeReset("game");

      //  ----获取折扣信息接口Start----
      this.getDiscountInfo({'game_slug':this.game_id});
      //  ----获取折扣信息接口End----

      serverList({ game_id: this.game_id, os: 2 }).then((response) => {
        if (response.length == 0) {
          this.$toast({
            message: "该游戏暂无区服",
            duration: 500,
          });
        } else {
          this.$toast({
            message: "获取区服列表成功",
            duration: 500,
          });
          this.serverList = response;
          this.roleText = this.moneyText = "";
        }
      }).catch(() => {});
    },
    //  获取档位
    products(){
      stripeMoneyList({game_type:9, os_type:2}).then((response) => {
        this.$toast({
          message: "获取充值金额列表成功",
          duration: 500,
        });
        this.moneyList = response;
      }).catch(() => {});
    },
    serverConfirm(value) {
      this.serverText = value.text;
      this.serverShowPicker = false;
    },
    moneyConfirm(value) {
      this.moneyText = value.text;
      this.money = value.money;
      this.moneyShowPicker = false;
      this.discount_show = false;
      this.coupons = [];
      this.disabledCoupons = [];
    },
    asyncValidator(val) {
      roleInfo({ game_id: this.game_id, server_name: this.serverText, role_name: val, os: 2 }).then((response) => {
        this.user_id = response.user_id;
        this.checkRoleBoole = true;
      }).catch((res) => {
        if (res.code === -1) {
            console.log('角色不存在不执行订单创建操作！' + JSON.stringify(res))
            this.checkRoleBoole = false;
            return;
        }
      });
    },
    roleChange() {
      this.moneyText = '';
    },
    getGameList(data = undefined) {
      gameList(data)
        .then((response) => {
          this.$toast({
            message: "获取游戏列表成功",
            duration: 500,
          });
          this.gameList = response;
        })
        .catch(() => {});
    },
    changeReset(changeSetup) {
      switch (changeSetup) {
        case "all":
          this.user_id = 0;
          this.account = "";
        /* falls through */
        case "account":
          this.osText = "";
          console.log("changeSetup account");
        /* falls through */
        case "os":
          this.gameList = [];
        /* falls through */
        case "game":
          this.serverText = "";
        /* falls through */
        case "server":
          this.roleText = "";
          this.roleId = 0;
        /* falls through */
        case "role":
          this.moneyText = this.product_name = "";
          this.discount_show = false;
          this.product_id = 0;
          this.payTypeId = "2";
          break;
      }
    },
    onCouponChange(index) {
      this.showCouponList = false
      this.chosenCoupon = index
      if (index >= 0) {
        this.couponId = this.coupons[index]["coupon_id"]
      } else {
        this.couponId = undefined
      }
    },
    resetMoney() {
      this.moneyText = this.product_name = this.product_id = undefined
    },
    // 创建订单
    orderStore() {
      const payTypeId = parseInt(this.payTypeId)
      console.log('this.user_id：' + this.user_id)
      if (this.checkRoleBoole) {
        console.log('this.checkRoleBoole1：' + this.checkRoleBoole)
        commonCreateOrder({
          user_id: this.user_id,
          game_id: this.game_id,
          server: this.serverText,
          role_name: this.roleText,
          product_id: this.product_id,
          money: this.money,
          os: 2,
          pay_type_id: payTypeId,
          coupon_id: this.couponId,
          game_type_name: 'darkxiyouxcx'
        }).then((response) => {
          this.resetMoney()
          this.payState = false
          // 如果代金券直接减完支付完成
          if (response.pay_success && response.pay_success === true) {
            this.$toast.success('支付成功')
          } else {
            this.toPay(response.number)
          }
        }).catch(() => {
          this.payState = false
        })
      } else {
        console.log('this.checkRoleBoole2，验证角色不通过：' + this.checkRoleBoole)
      }
    },
    // 发起支付
    toPay(orderNum) {
      Toast.loading({
        message: "发起支付中，请稍等!",
        forbidClick: true,
        duration: 0,
      });
      const payTypeId = parseInt(this.payTypeId)
      if (payTypeId === PAY_TYPE_WX) {
        this.payWx(orderNum)
      } else if (payTypeId === PAY_TYPE_ALIPAY) {
        this.payAlipay(orderNum)
      } else if (payTypeId === PAY_TYPE_PAYPAL) {
        this.payPaypal(orderNum)
      } else if (payTypeId === PAY_TYPE_STRIPE) {
        this.payByStripe(orderNum)
      }
    },
    payWx(orderNum) {
      payWxRedirect(orderNum).then((response) => {
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    // 支付宝
    payAlipay(orderNum) {
      // 跳转中间页
      Toast.clear(true);
      console.log("跳转支付中间页");
      const key = Base64.encode(
        JSON.stringify({
          user_id: this.user_id,
          number: orderNum,
          pay_type: parseInt(this.payTypeId),
        })
      )
      this.$router.push({
        path: "/darkxiyouxcx/mid",
        query: {
          key: key,
        },
      })
    },
    // 保存用户信息
    saveUserRole() {
      const account_item = {
        account: this.account,
        user_id: this.user_id,
        osId: 2,
        osText: this.osText,
        game_id: this.game_id,
        gameList: this.gameList,
        serverText: this.serverText,
        serverList: this.serverList,
        roleText: this.roleText
      };
      if (this.accountList.indexOf(this.account) === -1) {
        this.accountList.push(this.account)
      }
      console.log("accountList", JSON.stringify(this.accountList));
      if (localStorage.role_info) {
        let role_info =
          typeof localStorage.role_info === "string"
            ? JSON.parse(localStorage.role_info)
            : localStorage.role_info
        role_info[this.account] = account_item
        localStorage.role_info = JSON.stringify(role_info)
      } else {
        localStorage.role_info = JSON.stringify({
          [this.account]: account_item,
        })
      }
    }
  },
};
</script>

<style>
.pay_icon {
  width: 35px;
  height: 44px;
  float: left;
}
.pay_desc {
  float: left;
  display: block;
  line-height: 44px;
  margin-left: 5px;
}
.title_coupon {
  margin-right: 5px;
}
</style>
